/* Web3Page.css */
.web3-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(to right, #141E30, #243B55); /* Dark gradient for a futuristic look */
  color: #ffffff;
  padding: 20px;
  text-align: center;
  position: relative; /* Needed for positioning the button */
}

.banner {
  padding: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px); /* Frosted glass effect */
  max-width: 600px;
  width: 100%;
  animation: fadeIn 1.5s ease-out;
}

.banner h1 {
  margin: 0;
  font-size: 2.5em;
  color: #FFDA79;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  animation: glow 1.5s infinite alternate;
}

.banner p {
  margin-top: 20px;
  font-size: 1.2em;
  line-height: 1.5;
}

.home-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: transparent;
  border: 1px solid #FFDA79;
  color: #FFDA79;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.home-button:hover {
  background: #FFDA79;
  color: #141E30;
}

.home-button:focus {
  outline: 2px solid #FFDA79;
  outline-offset: 2px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 218, 121, 0.5);
  }
  to {
    text-shadow: 0 0 20px rgba(255, 255, 255, 1), 0 0 40px rgba(255, 218, 121, 1);
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 600px) {
  .banner h1 {
    font-size: 2em;
  }

  .banner p {
    font-size: 1em;
  }

  .home-button {
    padding: 5px 15px;
  }
}
