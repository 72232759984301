@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root {
  --primary-color: #007bff;
  --hover-color: #0056b3;
  --active-color: #004085;
  --background-color: #f7f9fc;
  --text-color: #333;
  --slogan-color: #555;
  --button-text-color: #fff;
  --shadow-color: rgba(0, 0, 0, 0.1);
}

.home-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--background-color); /* Light background for better contrast */
  padding: 0 20px; /* Responsive padding */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.centered {
  text-align: center;
  max-width: 600px; /* Limit content width for better readability */
  margin: auto;
}

.project-name {
  font-family: 'Montserrat', sans-serif;
  font-size: clamp(2rem, 4vw + 1rem, 3rem); /* Responsive font size */
  font-weight: 700; /* Bold text for emphasis */
  color: var(--text-color); /* Darker text for better contrast */
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px var(--shadow-color); /* Subtle shadow */
}

.slogan {
  font-family: 'Montserrat', sans-serif;
  font-size: clamp(1rem, 2.5vw + 0.5rem, 1.5rem); /* Responsive font size */
  color: var(--slogan-color); /* Lighter grey for less emphasis */
  margin-bottom: 2rem;
  line-height: 1.4; /* Better line spacing for readability */
}

.start-button {
  display: inline-block;
  padding: 12px 30px; /* Increased padding for better touch target */
  font-size: 1.1rem;
  color: var(--button-text-color); /* Button text color */
  background-color: var(--primary-color); /* Default button color */
  border: none;
  border-radius: 30px; /* More rounded for a modern look */
  box-shadow: 0 4px 6px var(--shadow-color); /* Soft shadow for depth */
  cursor: pointer;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  text-decoration: none;
}

.start-button:hover {
  transform: translateY(-3px); /* Slightly raise button on hover */
  background-color: var(--hover-color); /* Darker shade on hover */
}

.start-button:focus {
  outline: 2px solid var(--hover-color); /* Focus outline for accessibility */
  outline-offset: 2px;
}

.start-button:active {
  transform: translateY(-1px); /* Less transformation for active state */
  background-color: var(--active-color); /* Even darker shade on active */
}

.footer {
  position: fixed;
  bottom: 10px;
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
  color: var(--slogan-color);
  font-family: 'Montserrat', sans-serif;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 0;
}

.softdrakula-link {
  color: inherit; /* Inherit the text color */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Bold for emphasis */
}

.softdrakula-link:hover {
  text-decoration: underline; /* Underline on hover */
}

.softdrakula-link:focus {
  outline: 2px solid var(--hover-color); /* Focus outline for accessibility */
  outline-offset: 2px;
}

.orange-letter {
  color: orange; /* Specific color for the "D" */
}

@media (max-width: 768px) {
  .project-name {
    font-size: clamp(1.5rem, 4vw + 1rem, 2.5rem); /* Adjusted for smaller screens */
  }

  .slogan {
    font-size: clamp(1rem, 3vw + 0.5rem, 1.2rem); /* Adjusted for smaller screens */
  }

  .start-button {
    padding: 10px 20px;
    font-size: 1rem;
  }
}

/* Reduced motion for accessibility */
@media (prefers-reduced-motion: reduce) {
  .start-button {
    transition: none;
  }
  .start-button:hover,
  .start-button:active {
    transform: none;
  }
}
