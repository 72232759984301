body {
  margin: 0;
  padding: 0;
  font-family: 'Lora', serif;
  background-color: #f0f0f0;
}

.presentation-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background-image: linear-gradient(to bottom right, #0f3057, #3585b7);
  text-align: center;
}

.book-container {
  width: 90%;
  max-width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.page {
  display: none;
  overflow-y: auto;
  padding: 10px;
}

.page.active {
  display: block;
}

@keyframes textAppear {
  from { opacity: 0; }
  to { opacity: 1; }
}

.page.active h1,
.page.active h2,
.page.active h3,
.page.active p {
  opacity: 0;
  animation: textAppear 2s forwards;
  font-size: 1rem; /* Adjusted to rem units */
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.6;
  margin: 0 0 10px 0;
}

@keyframes colorChange {
  0%, 100% { color: #333; }
  25% { color: #ff7f50; }
  50% { color: #32cd32; }
  75% { color: #1e90ff; }
}

.page.active h2 {
  animation: colorChange 8s infinite;
}

.title {
  font-size: 2rem;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  position: relative;
  margin-bottom: 20px;
}

.glow-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  color: #fff;
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #ff00ff, 0 0 20px #ff00ff, 0 0 30px #ff00ff;
  animation: glowAnimation 2s infinite alternate;
}

@keyframes glowAnimation {
  0% {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #ff00ff, 0 0 20px #ff00ff, 0 0 30px #ff00ff;
  }
  50% {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #00ff00, 0 0 20px #00ff00, 0 0 30px #00ff00;
  }
  100% {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #00ffff, 0 0 20px #00ffff, 0 0 30px #00ffff;
  }
}

.navigation-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.navigation-buttons button {
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid #ccc;
  outline: none;
  cursor: pointer;
  font-size: 1rem; /* Adjusted to rem units */
  font-weight: 700;
  color: #333;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
  box-sizing: border-box;
}

.navigation-buttons button::before,
.navigation-buttons button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
  transition: width 0.3s, height 0.3s, top 0.3s, left 0.3s;
  z-index: -1;
}

.navigation-buttons button:hover::before,
.navigation-buttons button:hover::after {
  width: 300%;
  height: 300%;
  top: -100%;
  left: -100%;
}

.navigation-buttons button:hover {
  background-color: #1c4978;
  color: #fff;
}

.end-presentation-button {
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 1.125rem; /* Adjusted to rem units */
  font-weight: bold;
  color: #fff;
  background-color: #3e8e41;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 2;
  overflow: hidden;
  opacity: 0;
}

.end-presentation-button.show {
  opacity: 1;
  transform: scale(1);
}

.end-presentation-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.2);
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.5s ease-in-out;
  border-radius: 50%;
  z-index: 1;
}

.end-presentation-button.show::before {
  transform: translate(-50%, -50%) scale(1);
}

.end-presentation-button:hover::before {
  transform: translate(-50%, -50%) scale(1.2);
}

.end-presentation-button:hover {
  transform: scale(1.1);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.back-button {
  padding: 10px 20px;
  background-color: #1c4978;
  color: #fff;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.back-button:hover {
  background-color: #0c2c50;
  transform: scale(1.05);
}

@media (min-width: 768px) {
  .presentation-page {
    flex-direction: row;
    justify-content: space-between;
  }

  .book-container {
    width: 70%;
    max-width: 800px;
    padding: 20px;
  }

  .page.active h1,
  .page.active h3,
  .page.active h4,
  .page.active p {
    font-size: 1.25rem; /* Adjusted to rem units */
  }

  .title {
    font-size: 2.5rem;
  }

  .navigation-buttons {
    flex-direction: row;
    gap: 20px;
    width: auto;
  }

  .navigation-buttons button {
    font-size: 1.125rem; /* Adjusted to rem units */
    width: auto;
  }
}
